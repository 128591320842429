import { createForm, yup } from '@codeleap/common'

export const authorValidationRules = {
  full_name: {
    min: 3,
    max: 30,
  },
  organisation: {
    min: 3,
    max: 60,
  },
  contribution: {
    min: 3,
  },
  order: {
    validate: (value) => {
      const _value = Number(value)
      const isValid = Number.isInteger(_value) && _value > 0
      return {
        valid: isValid,
        message: isValid ? '' : 'Value must be an integer number greater than 0',
      }
    },
  },
}

export const authorForm = createForm('createAuthorManualInvite', {
  full_name: {
    type: 'text',
    label: 'Full name',
    placeholder: 'Enter a name',
    validate: yup.string().min(authorValidationRules.full_name.min, `Minimum of ${authorValidationRules.full_name.min} characters`)
      .max(authorValidationRules.full_name.max)
      .required('This is a required field'),
  },
  organisation: {
    type: 'text',
    label: 'Organisation',
    placeholder: 'Enter a organisation',
    validate: yup.string().min(authorValidationRules.organisation.min, `Minimum of ${authorValidationRules.organisation.min} characters`)
      .max(authorValidationRules.organisation.max)
      .required('This is a required field'),
  },
  email: {
    type: 'text',
    label: 'Email',
    placeholder: 'example@email.co.uk',
    // TODO - i think this will not work on validation
    validate: yup.string().email().required('This is a required field'),
  },
  contribution: {
    type: 'text',
    label: 'Contribution',
    placeholder: 'Enter a contribution',
    validate: yup.string().min(authorValidationRules.contribution.min, `Minimum of ${authorValidationRules.contribution.min} characters`)
      .required('This is a required field'),
    // validate: yup.string().min(15).max(1024),
  },
  region: {
    label: 'Country',
    placeholder: 'Select a country',
    type: 'select',
    validate: yup.string().required('This is a required field').nullable(),
    searchable: true,
  },
  tags: {
    type: 'select',
    label: 'Scientific fields',
    placeholder: 'Search a field',
    multiple: true,
    searchable: true,
    validate: (value) => ({ valid: value?.length > 0, message: 'This is a required field' }),
  },
  order: {
    type: 'text',
    label: 'Author position Nº',
    placeholder: 'Enter a position number',
    validate: (value) => authorValidationRules.order.validate(value),
  },
})
