import React from 'react'
import { variantProvider, AppForms } from '@/app'
import { AnyFunction, deepEqual, onMount, useForm, useMemo } from '@codeleap/common'
import { View, Text, TextInput, ActionIcon, Link, SelectForm } from '@/components'
import { BaseForm, FormComponentProps } from './BaseForm'
import { APIClient } from '@/services'
import { PublicationAuthor, ScientificTag } from '@/types'
import { authorInitialState, usePublicationForm } from './usePublicationForm'
import { getSelectValues } from '@/utils'

const Form = (
  props: FormComponentProps<Partial<PublicationAuthor>> & {
    scientificTags: ScientificTag[]
    disabled?: boolean
    saveEnabled?: AnyFunction
    regions: any
    onDelete: (item: PublicationAuthor) => void
  },
) => {
  const { isFirst, value, onChangeValue, deleteForm, onDelete, scientificTags, disabled, saveEnabled, regions, index } = props
  const form = useForm(AppForms.authorForm, { initialState: value })
  const showDeleteIcon = !(isFirst || disabled)

  const regionValue = useMemo(() => getSelectValues(form.values.region, regions, true), [form.values.region, regions?.length])
  const tagsValue = useMemo(() => getSelectValues(form.values.tags, scientificTags, true), [form.values.tags, scientificTags?.length])

  onMount(() => saveEnabled?.(form.isValid))

  const handleDeleteForm = () => {
    setTimeout(() => {
      // @ts-ignore
      onDelete(value)
      deleteForm(index)
    }, 1000)
  }

  const handleForm = (field: 'full_name' | 'organisation' | 'email' | 'contribution' | 'tags' | 'region') => ({
    ...form.register(field),
    disabled,
    onBlur: () => {
      const newValues = form.values as Partial<PublicationAuthor>
      if (!deepEqual(newValues, value)) {
        onChangeValue(newValues)
        saveEnabled?.(form.isValid)
      }
    },
  })

  return (
    <View style={!isFirst ? styles.newForms : {}} variants={['column', 'gap:3']}>
      <View variants={['row', 'alignCenter', 'justifySpaceBetween']}>
        <Text text={isFirst ? 'Main Author' : 'Author'} variants={['h5', 'color:neutral8']} />
        {showDeleteIcon && (
          <ActionIcon
            icon='trash'
            variants={['destructive:minimal', 'normalize']}
            debugName='Remove author form'
            onPress={handleDeleteForm}
            debounce={1000}
          />
        )}
      </View>
      <View style={styles.grid}>
        <TextInput {...handleForm('full_name')} disabled={isFirst || disabled} />
        <TextInput {...handleForm('order')} />
        <SelectForm
          {...handleForm('region')}
          loadInitialValue
          options={regions}
          value={regionValue}
          fieldName={`region:${index}`}
        />
        <TextInput {...handleForm('organisation')} />
        <TextInput {...handleForm('email')} />
        <SelectForm
          {...handleForm('tags')}
          value={tagsValue}
          options={scientificTags}
          leftIcon={{ name: 'flask-conical' }}
          styles={{
            leftIconTouchableWrapper: styles.leftIconTouchableWrapper,
          }}
          fieldName={`tags:${index}`}
        />
      </View>
      <TextInput
        variants={['fullWidth']}
        style={{ whiteSpace: 'pre-line' }}
        description={<Text variants={['p1', 'color:neutral7', 'marginBottom:1', 'regular']}>
          CRediT (Contributor Roles Taxonomy) is a high-level taxonomy, including 14 roles, that can be used to represent the roles typically played by contributors to research outputs. Choose one or more from this list:
          <br /><br />Conceptualization, Data curation, Formal Analysis, Funding acquisition, Investigation, Methodology, Project administration, Resources, Software, Supervision, Validation, Visualization, Writing – original draft, Writing – review & editing.
          <br /><br />Please visit <Link variants={['p1', 'neutral7', 'thin']} openNewTab to='https://credit.niso.org/' text='https://credit.niso.org/' /> for more information.
        </Text>}
        {...handleForm('contribution')}
      />
    </View>
  )
}

export const AuthorForm = () => {
  const { authorForms, setAuthorForms, isSaved_in_drafts, setAuthorsIsValid } = usePublicationForm()
  const { delete: deleteAuthor } = APIClient.Publications.authorsManager.useDelete()
  const { scientificTags, profile, regions } = APIClient.Session.useSession()

  function onDelete(item: PublicationAuthor) {
    if (item.id) deleteAuthor(item)
  }

  function handleInitialOrder() {
    setAuthorForms((prevForms) => [...prevForms, { ...authorInitialState, order: prevForms?.length + 1 }])
  }

  return (
    <BaseForm<Partial<PublicationAuthor>>
      forms={authorForms}
      setForms={setAuthorForms}
      initialState={authorInitialState}
      showAddMoreButton={isSaved_in_drafts}
      addMoreButtonProps={{ onPress: handleInitialOrder }}
      formComponent={(props) => {
        return (
          <Form
            scientificTags={scientificTags}
            disabled={profile?.current_role === 'editor' || profile?.current_role === 'publisher' || !isSaved_in_drafts}
            saveEnabled={setAuthorsIsValid}
            regions={regions}
            onDelete={onDelete}
            {...props}
          />
        )
      }}
    />
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    grid: {
      display: 'grid',
      // prevent grid from overflowing horizontally when large texts are typed on the text input
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      columnGap: theme.spacing.value(5),
      rowGap: theme.spacing.value(3),
      [theme.media.down('small')]: {
        // prevent grid from overflowing horizontally when large texts are typed on the text input
        gridTemplateColumns: 'minmax(0, 1fr)',
      },
    },
    newForms: {
      ...theme.spacing.padding(3),
      ...theme.border.neutral5({ width: 1 }),
      borderRadius: theme.borderRadius.large,
    },
    leftIconTouchableWrapper: {
      ...theme.spacing.marginRight(1),
    },
  }),
  true,
)
